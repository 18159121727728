import React from "react"
import { Flex, Box, Heading } from "@chakra-ui/react"

import { StaticImage } from "gatsby-plugin-image"

import MethodologyContent from "./methodology-content"

const styles = {
  root: {
    width: "100%",
    px: { base: "16px", md: "24px", lg: "24px" },
  },
  "inner-box": {
    marginX: "auto",
    my: { base: "20px", md: "20px", lg: "60px" },
    maxWidth: "812px",
  },
  "top-section": {
    flexDirection: "column",
    alignItems: "center",
  },
  heading: {
    fontSize: "32px",
    fontWeight: 700,
    lineHeight: "40px",
    textAlign: "center",
    letterSpacing: "0em",
  },
  "allstar-text": {
    textAlign: "center",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    color: "#129459",
  },
  hero: {
    mt: { base: "16px", md: "16px", lg: "24px" },
    width: { base: "144px", md: "144px", lg: "160px" },
  },
}

const SectionBody = () => (
  <Box sx={styles.root}>
    <Box sx={styles["inner-box"]}>
      <Flex sx={styles["top-section"]}>
        <Heading as="h2" sx={styles["allstar-text"]}>
          Healthcare Research All-Stars
        </Heading>
        <Heading as="h2" sx={styles.heading} mt="8px">
          Research Methodology
        </Heading>
        <Box sx={styles.hero} mb="40px">
          <StaticImage
            src="../../../images/allstar/Healthcare Research All-Stars 2024 - Logo.png"
            alt="Healthcare Research All-Stars Logo"
            placeholder="blurred"
          />
        </Box>
      </Flex>
      <MethodologyContent />
    </Box>
  </Box>
)

export default SectionBody
