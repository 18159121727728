import React from "react"

import Layout from "../components/allstar/layout"

import SectionBody from "../components/allstar/methodology/section-body"

const MethodologyPage = props => {
  return (
    <Layout location={props.location} title={"Methodology"}>
      <SectionBody />
    </Layout>
  )
}

export default MethodologyPage
